export const accountTypesEnum = () => {
  return {
    CC: 'Conta corrente',
    CI: 'Conta poupança/Investimento',
    OC: 'Outros'
  }
}

export const categoryGroupsEnum = () => {
  return {
    R: 'Receita',
    D: 'Despesa',
    O: 'Outros'
  }
}

export const colorSwatches = () => {
  return [
    ['#0000FF', '#00FF00', '#00FFFF', '#FF0000', '#FF00FF', '#FFFF00', '#AAAAAA'],
    ['#0000AA', '#00AA00', '#00AAAA', '#AA0000', '#AA00AA', '#AAAA00', '#555555'],
    ['#000055', '#005500', '#005555', '#550000', '#550055', '#555500', '#000000']
  ]
}

export const recordsTypesEnum = () => {
  return [
    { id: 1, name: 'Receitas', type: 'R' },
    { id: 0, name: 'Despesas', type: 'D' },
    { id: 2, name: 'Transferência', type: 'T' }
  ]
}

export const cashFlowFilterType = [{
  title: 'Fluxo de caixa',
  value: false
}
// {
//   title: 'Fluxo de caixa X previsto',
//   value: true
// }
]
