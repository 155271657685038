<template>
  <div>
    <v-container fluid>
      <h1 class="titulo">CATEGORIAS</h1>
      <v-skeleton-loader type="table" :loading="skLoader" :transition-group="'fade-transition'">
        <v-list>
          <v-card v-for="(groupName, index) in categoryGroupsEnum" :key="index" elevation="5" style="margin: 16px;">
            <v-card-text>
              <OrganizzeAddAnyButton  :title="`Adicionar ${groupName}`" @onClick="openModalCadastro({ categoryGroup: index })"/>

              <v-list-group v-for="(category, index) in categoryGroupsMapped[index]" :key="index" no-action sub-group>
                <template v-slot:activator>
                  <v-list-item-icon style="margin-right: 8px;">
                    <v-icon :color="category.colour">mdi-arrow-right-bold-circle</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-container>
                      <v-row no-gutters>
                        <span style="font-weight: bold;">{{category.name}}</span>
                        <v-spacer></v-spacer>
                        <OrganizzeActionButton
                          v-if="category.jsonFlags ? !category.jsonFlags.InportOnly : true"
                          :canEdit="permiteAcao($route, 'edit')"
                          :canDelete="permiteAcao($route, 'delete')"
                          @onEdit="editModalCadastro({ category })"
                          @onDelete="openModalExcluir({ category })"
                        />
                      </v-row>
                    </v-container>
                  </v-list-item-content>
                </template>

                <v-list-item v-for="(subCategory, index) in category.subCategories" :key="index" sub-group>
                  <v-list-item-content>
                    <v-container>
                      <v-row no-gutters>
                        <span class="subCategory">{{subCategory.name}}</span>
                        <v-spacer></v-spacer>
                        <OrganizzeActionButton
                          v-if="subCategory.jsonFlags ? !subCategory.jsonFlags.InportOnly : true"
                          :canEdit="permiteAcao($route, 'edit')"
                          :canDelete="permiteAcao($route, 'delete')"
                          @onEdit="editModalCadastro({ category: subCategory })"
                          @onDelete="openModalExcluir({ category: subCategory })"
                        />
                      </v-row>
                    </v-container>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </v-card-text>
          </v-card>
        </v-list>
      </v-skeleton-loader>
    </v-container>
    <ModalCadastro/>
    <ModalExcluir/>
  </div>
</template>

<script>
  import { categoryGroupsEnum } from '@/utils/tempUtils'
  import Events from '@/core/service/events'
  import { mapGetters, mapActions } from 'vuex'
  import { errorSnackbar } from '@/core/service/utils'
  // import { incRequestCounter, REQUEST_KEY_ALL } from '@/utils/miscellaneous'

  const namespaceStore = 'organizzeFinanceiroCategorias'

  export default {
    name: 'OrganizzeFinanceiroCategorias',

    components: {
      OrganizzeAddAnyButton: () => import('@/views/components/organizze/addAnyButton'),
      ModalCadastro: () => import('./components/modalCadastro'),
      OrganizzeActionButton: () => import('@/views/components/organizze/actionButton'),
      ModalExcluir: () => import('./components/modalExcluir')
    },

    computed: {
      ...mapGetters(namespaceStore, ['categoryGroupsMapped']),
      ...mapGetters('roles', ['permiteAcao']),
    },

    mounted () {
      const self = this
      self.buscarCategorias()

      Events.$on('index::getCategories', () => { self.buscarCategorias() })
    },

    data: () => ({
      skLoader: true,
      categoryGroupsEnum: null
    }),

    created () {
      const self = this
      self.categoryGroupsEnum = categoryGroupsEnum()
    },

    methods: {
      ...mapActions(namespaceStore, ['getCategories']),
      openModalCadastro (data) { Events.$emit('modalCadastro::open', data) },
      editModalCadastro (data) { Events.$emit('modalCadastro::edit', data) },
      openModalExcluir (data) { Events.$emit('modalExcluir::open', data) },

      buscarCategorias () {
        const self = this

        self.skLoader = true
        self.getCategories().then(() => {}).catch(e => {
          console.error(e)
          errorSnackbar(e)
        }).finally(() => {
          self.skLoader = false
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~@/assets/styles/helpers/_variables.scss';
  .subCategory {
    font-size: 14px;
    color: #888888;
  }
</style>